import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
html {
  
height: 100vh;
    background-color: #fffff9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-height: 600px) {
        justify-content: flex-start;
  }
  @media screen and (max-width: 800px) {
        justify-content: flex-start;

  }
  
  }

  body {

  }

`;

export default GlobalStyles;
