import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const HeroStyles = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fffff9;
        .logo-container {
                margin-top: 12px;
                width: 280px;
        }
        .text-container {
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 45vw;
        }
        h2 {
                margin: 0;
                width: 100%;
                font-size: 48px;
                line-height: 48px;
                font-family: 'Playfair Display', 'serif';
                font-weight: 400;
                text-align: center;
        }
        p {
                margin-top: 51px;
                margin-bottom: 0;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
                font-family: 'Raleway', 'sans-serif';
                font-weight: 400;
                text-align: center;
        }
        button {
                border: none;
                margin-bottom: 12px;
                border-radius: 3px;
                padding: 10px 18px;
                color: #fffff9;
                font-family: 'Raleway', 'sans-serif';
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                background-color: #577399;
                margin-top: 54px;
                text-decoration: none;
                cursor: pointer;
                transition: background-color ease 0.15s;

                :hover {
                        background-color: #86a6d2;
                }
        }
        a {
                color: #577399;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;

                text-decoration: none;
                transition: color ease 0.15s;
                :hover {
                        color: #86a6d2;
                }
        }

        @media screen and (max-width: 700px) {
                h2 {
                        font-size: 30px;
                        line-height: 36px;
                }
                p,
                a {
                        font-size: 16px;
                        line-height: 24px;
                }
                .text-container {
                        width: 75vw;
                }
        }
`;

function Hero() {
        return (
                <HeroStyles>
                        <div className="logo-container">
                                <StaticImage
                                        src="../images/cow+field_logo stacked_black_RGB.png"
                                        alt="The Cow and Field Logo"
                                />
                        </div>
                        <div className="text-container">
                                <h2>Our new website</h2>
                                <h2>is on the way.</h2>
                                <p>
                                        In the meantime you can find us on{' '}
                                        <a href="https://www.instagram.com/cowandfield">Instagram</a> or get in touch if
                                        you would like help with an event.
                                </p>
                        </div>
                        <button
                                type="button"
                                onClick={(e) => {
                                        window.location = 'mailto:info@cowandfield.co.uk';
                                        e.preventDefault();
                                }}
                        >
                                Get in touch
                        </button>
                </HeroStyles>
        );
}

export default Hero;
